.player-controls {
	align-items: center;
	justify-content: center;
}

progress {
	width: 100%;
}

.seek-obj-container {
	position: relative;
}

.start-time {
	position: absolute;
	left: 10px;
	top: 19px;
}

.end-time {
	position: absolute;
	top: 19px;
	right: 5px;
}

progress {
    
    &[value] {
    	-webkit-appearance: none;
    	appearance: none;
    	background-color: map-get($colors, "color-f1");
    	color: blue;
    	height: 4px;
    	border-radius: 2px;
    }
    
    &[value]::-moz-progress-bar {
    	background-color: map-get($colors, "color-f1");
    	border-radius: 2px;
    	border: 1px solid map-get($colors, "color-f1");
    	color: blue;
    }
        
    &[value]::-webkit-progress-bar {
    	background-color: map-get($colors, "color-f1");
    	border-radius: 2px;
    	border: 1px solid map-get($colors, "color-f1");
    	color: blue;    
	}
    
    &::-moz-progress-value {
    	background-color: blue;
    	height: 4px;
    	width: 4px;
    	background: map-get($colors, "color-f1");
    }
        
    &::-webkit-progress-value {
    	background-color: blue;
    	height: 4px;
    	width: 4px;
    	background: map-get($colors, "color-f1");
    }
    
}


.progress {
	height: 15px;
	width: 15px;
	background: map-get($colors, "color-f1");
	position: absolute;
	display: inline;
	top: 9px;
	border-radius: 50%;
	left: 0;
}



span.active {
	background: map-get($colors, "color-f1");
}

.volume {
    
    width: 35px;
       
    span{
        width: 4px;
        background: map-get($colors, "color-e2");
        position: absolute;
        bottom: -15px; 
        cursor: pointer;
        border-radius: 2px;
    }
       
    span.active{
      background: map-get($colors, "color-f1");
    }
    
    &-1 {
    	height: 20px;
    	right: 24px;
    
        @include respond-below('sm') {
            right: 60%;
            height: 20px;
        }  
    	
    }
    
    &-2 {
    	height: 23px;
    	right: 16px;
    	
        @include respond-below('sm') {
        	right: 50%;
        	height: 23px;
        }  
    }


    &-3 {
    	height: 26px;
    	right: 8px;
    	
        @include respond-below('sm') {
        	right: 40%;
        	height: 26px;
        }  
    }
    
    &-4 {
    	height: 29px;
    	right: 0px;
    	
        @include respond-below('sm') {
        	right: 30%;
        	height: 29px;
        }  
    	
    }   
}


.mobile.volume span {
	bottom: 19%;
}


.play-btn {
	margin-right: 10px;
	margin-top: 9px;
}

.play-btn.pause {
}



