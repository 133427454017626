// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

/* GLOBALS */

body {
    color: map-get($colors, "black");
    scroll-behavior: smooth;
    min-height: 100vh;
    margin-top: 180px;
    
    
    @include respond-below(md) {
        margin-top: 90px;
    } 
}

.serif {
    font-family: 'GeogrotesqueW01-Regular1185161';
    
    &-strong {
        font-family: 'Geogrotesque W01 Medium1185174';
    }
    
}

h1, h2, h3 {
    font-family: 'GeogrotesqueW01-Regular1185161';
}


h1, .h1 {
    @include respond-below('md') {
        font-size: $font-size-base * 2;
    }
}

.h2, h2 {
    @include respond-below('md') {
        font-size: $font-size-base * 1.8;
    }
}

.h3, h3 {
    @include respond-below('md') {
        font-size: $font-size-base * 1.5;
    }
}

.h4, h4 {
    @include respond-below('md') {
        font-size: $font-size-base * 1.2;
    }
}

.h5, h5 {
    @include respond-below('md') {
        font-size: $font-size-base * 1;
    }
}

.responsive-container {
    @extend .pl-3, .pl-lg-5;
}

.modal-backdrop {
    background: map-get($backgrounds, "color-f1-60");
    
    
    &.opacity-0 {
        z-index: 0;
    }
}

.bg-diagonal {
    
    background-image: linear-gradient(135deg, #ffffff 30%, #dce7f3 30%, #dce7f3 50%, #ffffff 50%, #ffffff 80%, #dce7f3 80%, #dce7f3 100%);
    background-size: 14.14px 14.14px;
        
    
    &-grey {
        @extend .bg-diagonal;
        background-image: linear-gradient(135deg, #ffffff 30%, #ECEEEF 30%, #ECEEEF 50%, #ffffff 50%, #ffffff 80%, #ECEEEF 80%, #ECEEEF 100%);
    }    
}

.bayme-vbm-modal .modal-dialog{
    @extend .container;
}

.infobox {
    @extend .alert-dark, .alert;
    z-index: -1;
}


.col-right {
    
    min-width: 300px;
    
    @include respond-below('md') {
        min-width: 100%;
    }   
}

.hide-paged {
    @extend .d-none;
}

/* ul li */

.styled-li-before {
    content: "–";
    padding: 0;
    position: absolute;
    left: -15px;    
} 

.styled-li {
    position: relative;
    list-style: none;   
} 

ul.list-style-dash,
ul.textListDashed {

    margin-left: -20px;

    li:before {
        @extend .styled-li-before;
    }  
          
    li {
        @extend .styled-li;
    }    
}


/* elements */

.article {
    
    &-title {
    
        @extend .font-size-22, .serif, .text-primary, .hyphens-auto;
        
        &-small {
            @extend .article-title;
            @extend .font-size-16;
        }
            
        &-medium {
            @extend .article-title;
        }
                
        &-large {
            @extend .article-title;
            @extend .font-size-30;
        }
        
        &-black {
            @extend .text-body;    
        }
            
        &-white {
            @extend .text-white;    
        }
    }
    
    &-teaser {
        @extend .font-size-14, .text-black;
    }
    
    &-content { 
        
        &-text-cols {
        
            h2 {
                @extend .text-primary, .fs-5;
            }
        }
        
    }
        
}

.articles {
    
    &-search {
        
    }
    
    
    &-results {
        
    }
    
}

.parallax {
    
    &-image {
        @extend .position-absolute, .top-0, .w-100, .h-100, .h-md-auto, .container;
       
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;        
        
    }
    
}


.microline {
    
    @extend .d-block;
    @extend .font-size-12;
    @extend .p-0, .m-0;
    @extend .black;
    
    
    &-white {
        @extend .microline;
        color: #fff !important; 
    }  
    
        
    &-strong {
        @extend .microline;
        @extend .strong;
    }   
    
            
    &-light {
        @extend .microline;
        @extend .font-light;
    }
          
    &-primary {
        @extend .text-primary;
    }    
}

.link-icon {
    
    &-text {
        @extend .text-primary, .serif;
    }
        
    .icon {
        @extend  .text-primary, .icon-big;
    }
    
}


.selector {
    
    $selector: &;
    
    @extend .position-relative;
    
    &-drop {
        @extend .d-flex, .align-items-center, .px-4, .py-2, .z-index-1, .position-relative;
    }
    
    &-options {
        padding-top: 95px;
        top: -20px;
        padding-bottom: 25px;
        @extend .text-start, .black, .serif, .collapse, .position-absolute, .bg-light-grey, .w-100;
        
    }
    
    &-option {
        @extend .pointer, .px-4, .py-1;
        
        &-selected {
           @extend .text-primary; 
        }
    }
    
    &-active .collapsed {
        
        @extend .bg-primary; 

              
        #{$selector}-icon,
        #{$selector}-selected,
        .icon-arrow {
            color: map-get($colors, "white") !important; 
        }
    }
    
}


.gallery {

    &-link {
        display: block;
        overflow: hidden;
        min-height: 220px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;    
    }
    
}


.filter {
    
    &-button {
        
        
        &-text-open {
            display: none;
        }        
        
        &-text-close {
            display: block;
        }
                
        &-arrow {
            @extend .icon-rotate-270, .text-primary;
        }
    }  
      
    &-button.collapsed {
        
        .filter-button-text-open {
            display: block;
        }    
            
        .filter-button-text-close {
            display: none;
        }
        
        .filter-button-arrow {
            @extend .icon-rotate-90, .text-black;
        }        
    }
}


/* event article */

.event {
    
    
    &-program {
        
        h3 {
            @extend .text-primary;
        }
        
        dd .onepagerCol2,
        dd .onepagerCol1 {
            
            p {
                @extend .serif, .text-primary, .mb-0, .font-size-18;
            }
        }
        
        dd .onepagerCol3 {
            p {
                
                span {
                }
            }
        }
        
    }
    
    
}


/* generic box */

.box {
    
    &-headline {
        
        @extend .serif, .text-primary, .fs-5;
        
    }

}
/* navi */

.generic-navi {
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        
        li {
            padding-top: 10px;
            position: relative;
            
            .icon {
                position: absolute;
                left: -27px;
                top: 12px;                   
            }
            
            ul {
                display: none;
                
                li {
                    @extend .font-size-15;
                    padding-top: 10px;
                }
            }
            
        }
        
        a {
            @extend .black;
            display: block;
        }
    }
    
    .icon {
        transition: 0.5s ease;
    }
    
    &-active {
        
        * {
            color: $primary !important; 
        }
        
        .icon {
            transform: rotate(90deg);
        }
    }

}


    
.numbers {
    
    h3 {
        display: table-caption;
    }
    
    display: table;
    
    &-row {
        display: table-row;
    }
    
    
    &-cell{
        
        display: table-cell;
        
        &-number {
            width: 1%;
        }
        
    }
    
}


/* top-topic box */

.top-topic {
    
    @extend .mb-4;
    
    
    &-text {
        
        @include respond-above('lg') {
            margin-right: 0;
            margin-left: 0;
            width: 45%;
            position: relative;
            right: -100px;
        }  
    }    
        
    
    &-img {
        @include respond-above('lg') {
            width: 55%;
        }
    }   
    
    &-left {
 
        @include respond-above('lg') {
            width: 90%; 
            margin-left: -100px; 
        }       
            
    } 
        
    &-right {

    }

}

/* about-us box */

.about-us-box {
    
    &-header {
        
        margin-bottom: 100px;
    }
    
    &-text {
        margin-top: -100px;
    }
    
    &-image {
        max-height: 450px;
        @include respond-below('lg') {
           max-height: 300px;
        }  
        
        @include respond-below('md') {
            max-height: none;
            width: 100%;
            display: block;
            height: fit-content;
        }        
    }    
}

/* service center */

.service-center {
    
    &-overview-shortcuts {
        padding-top: 90px !important;
        padding-right: 70px !important;
        
        
        &.show {
            z-index: 1;
        }
    }
        
    &-overview-list {
        @include respond-above('lg') {
            padding-left: 200px;
        }
    }
       
}

/* finders*/

.service-finder {       
            
    width: 200px;
        
        
    &-icon {
        font-size: 3rem;
        cursor: pointer;
        position: relative;
        z-index: 1;
    }
    

}


.finder {
    
    &-overlay {
        
        right: 25px;
        top: 0;
        
        &-header {
            padding-right: 100px !important;
        }
        
        &-service {
            width: 50vw;
        }       
        
    }    
}




// header image / header image sc

.header-image {
    
    min-height: 180px;
    
    &-expanded {
    }
    
    &-sc-text {
        @include respond-above('md') {
            margin-left: -100px;
        }
    }
        
    &-sc-img {
        @include respond-between(lg, xl) {
            width: 60%;
        }
    }
    
}

/* publication box */


.pub-overview-box-open {
   
    .publication-text {
        @extend .bg-grey;
    }
    
    .publication-active-bar {
        display: block;
    }
    
}

.publication {
    
    &-active {
        
        &-bar{
            display: none;
            bottom: -9px;
            position: relative;
            z-index: 1;
        }
                
    }
    
    &-image {
        
        img {
            max-height: 210px !important;
        }
    } 
    
    &-search-box-image {
        img {
            width: 300px !important;
        }
    }
}
            


/* article box */

.article {
    
    
    &-box {       
       @extend .col, .p-0, .pr-3;   
       @extend .text-black;        
    }    
}


.date {

    &-day {

        @extend .serif, .text-primary, .d-block, .position-relative, .p-1;
        
        line-height: 3.5rem;
        font-size: 4rem;
        margin-bottom: 0.5rem;
    
        @include respond-below('lg') {
            font-size: 4rem;
        }   
        
        &-circle {
            background:  map-get($colors, "color-e2");
            border-radius: 50% !important;
            padding: 10px;
        }   
        
        .square {
            position: absolute;
            z-index: -1;
            left: -10%;
            top: -10px;
            width: 120%;
        }
        
        .square:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }         
    }

}


/* links-box */

.links {
    
    &-box {
        a {
            @extend .d-block, .black, .serif;
        }        
    }
    
    &-link {
        
        @extend .font-size-12;            

        &-icon {
            @extend .icon-medium, .icon-strong, .align-bottom, .ml-2;
        }
        
        &-big{
            @extend .font-size-20;            
        }
    }
}

/* two-image-teaser */

.two-image-teaser {
    
    &-image {
        min-height: 330px;
        width: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
}

/* audio player */

.audio-box {
    
    .play-btn {
        margin-top: 0;
    }    
    
    .audio-bar {
        
        @extend .w-100, .position-relative;
        top: -5px;
        
        
        .seek-obj {
            height: 1px;
            margin-bottom: 2px;
        }
        
        .start-time, .end-time {
            font-size: 12px;
            top: 16px;
        }    
            
        .start-time {
            left: 20px;
        }
        
        .player-controls {
            cursor: pointer;
        }
        
    }
    
    .volume {
        span {
            @extend .bg-dark;
        }
        
        .active {
            @extend .bg-primary;
        }
    }
}

/* contact overlay */

.contact-overlay {
    
    @extend  .end-0, .position-fixed, .text-end;
   
    z-index: 50;
    top: 50%;
    
    @include respond-above('md') {
        top: 50%;
    }  
    
      
    &-info {
        
        @extend .bg-grey, .p-4, .row, .mt-md-n3, .text-start, .overflow-auto, .h-md-auto;
    }
    
    &-button {
        
        @extend .btn, .bg-primary, .px-3, .py-1, .white, .fs-4, .rounded-end, .serif;
        
        &[aria-expanded="true"]{
            .contact-overlay-button-text {
                display: block !important;
            }
                        
            .contact-overlay-button-icon {
                display: none !important;
            }
        }
        
        &-text {
            display: none;
        }
        
         
        &:hover{
            
            .contact-overlay-button-text {
                display: block;
            }
                        
            .contact-overlay-button-icon {
                display: none;
            }
            
        }        
    }
    
}

/* forms */

.btn,
button.form-control {
    @extend .rounded-pill;
} 

input[type=checkbox]+input[type=hidden]+label:before, input[type=checkbox]+label:before {
    border-radius: 0;
    height: 30px;
    width: 30px;
    top: -1px;
    border: 1px solid $primary;
    left: 3px;
    background: none;
}

input[type=checkbox]+label{
    padding-left: 45px;
}

input[type=checkbox]:checked+label:before {
    background: none;
    /*border: 1px solid $primary;*/
    border: none;
}


input[type=checkbox]:checked+label:after {
    border: 2px solid rgb(75, 135, 195);
    border-left: 0;
    border-top: 0;
    height: 26px;
    left: 11px;
    top: -4px;
    width: 14px;
}
   
        
        
.form-control {
    
    @at-root {
        input#{&} {
            height: 60px;
        }
    }
    
    &-checkboxes {
        
        &-green {
            
            input[type=checkbox]:checked+label:after {
                border: 2px solid map-get($colors, "green");
                border-left: 0;
                border-top: 0;
            }            
        }
        
    }
    
}

.selectize-control {

    @extend .black;

    .selectize-input {
        
        @extend .d-flex, .align-items-center, .justify-content-between;
        
        .item {
            width: auto;
            margin-right: 10px;
        }
        
    }
    
    .optgroup {
    
        .optgroup-header {
            @extend .serif;
        }
        
        .option {
            @extend .pl-3;
        }
    
    }
     
    .dropdown-active:after {
        top: 0px !important;
        font-size: 24px !important;
        content: "\e929" !important;
        width: 18px !important;
        padding: 0 !important;
    }
    
    .selectize-input:after {
        top: 0px !important;
        font-size: 24px !important;
        content: "\e929" !important;
        width: 18px !important;
        padding: 0 !important;
    }
    
    
}

/* custom css */

.bolder{
    font-weight: 900;
}

.finger{
    cursor: pointer ;
}
.rounded-10{
    border-radius: 10px;
}
.rounded-20{
    border-radius: 20px;
}
.rounded-30{
    border-radius: 30px;
}
.members-box-cont {
    overflow: hidden;
    position: relative;
    width: 100%;
}
.members-box .bg-diagonal {
    z-index: -1;
    top: 0;
    left: 200px;
}
.num-circle{
    width: 40px;
    height: 40px;
}
.article-item {
    height: 100%;
    position: absolute;
    width: 10px;
    z-index: 1;
}
.bg-light-green {
    background-color: #92d050;
}
.pimcore_editable input{
    display: block!important;
}
ol{
    padding-left: 1rem !important;
    
    p{
        margin-bottom: 0!important;
    }
    
}
.conditions ul{
    
    list-style: none;
    padding-left: 0 !important;
    
    li{
        display:flex;
    }
    
    li:before {
        content: "−";
        padding-right: 5px;
    }
}
.sans-serif{
        font-family: "Neue Haas Unica W01 Regular", sans-serif!important;
}

.square {
    width: 30px !important;
    height: 30px !important;
    background-color: #c9d2de;
}
.square.yellow{
    background-color: #ffcd32;
}

.square.green{
    background-color: #92d050;
}

.parts{
    a.btn, button {
        height: 45px;
        width: 45px;
        border-radius: 0 !important;
    }
    
    span.done {
        background-color: #92d050 !important;
    }
    a.done{
        background-color: #6faa31;
    }
}

.bg-dark-blue {
    background-color: #002850;
}
.jconfirm-box{
    background-color:#ffcd32!important
}

.jconfirm-buttons, .jconfirm-box{
    display: flex!important;
    align-items: center !important;
}

@media only screen and (max-width: 992px) {
    .jconfirm-box{
        display: block!important;
    }
}

@media only screen and (max-width: 768px) {
    .jconfirm-buttons{
        display: block!important;
    }
}

.jconfirm-title{
    font-size: 1.25rem !important;
}

.top-21{
    top: 21px;
}
.accordion.collapsed .icon-rotate-270{
    transform: rotate(90deg)!important;
}


.agile-line{
    height: 60px;
}

.line-result{
    height: 40px;
    background-color: #002850;
    z-index: 2;
    margin-top: 10px;
}

.line-result-success{
    height: 60px;
    /*background-color: rgba(214, 228, 238, 0.95);*/
    width: 35%;
    right: 0;
    border-top: 3px solid $primary;
   /* border-bottom: 3px solid #5687b9;*/
}

.sub-succes{
    height: 40px;
    background-color: #ced6e1;
    width: 100%;
    z-index: 1;
    margin-top: 10px;
    position: absolute;
}

.triangle-1{
    min-width: 20px;
    background: linear-gradient(to right top, #ffffff00 48%, #92d050 50%);
}

.triangle-2{
    min-width: 20px;
    background: linear-gradient(to right top, #ffffff00 48%, #7a9cbb 50%);
}

.triangle-3{
    min-width: 20px;
    background: linear-gradient(to right top, #ffffff00 48%, #ac153a 50%);
}

.middle-line{
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    z-index: 1;
    margin-top: 13px;
    margin-left: 70px;
}

.middle-line2{
    border-bottom: 2px solid #4f95c9;
    z-index: 1;
    margin-top: 13px;
    left: 30px;
    right: 0px;
}
.min-h-29 {
    min-height: 29px;
}

.selectize-dropdown [data-selectable], .selectize-dropdown .optgroup-header {
    padding: 5px 10px;
}

.w-60p{
    width: 60px!important;
}

.w-40p{
    width: 40px!important;
}

.w-35p{
    width: 35px!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.switch-button-cont {
    background-image: url(/public/img/switch-button-bg-arrows.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 90px;
    height: 44px;
    cursor: pointer;
    right: 35px;
}

.switch-button-cont.switch-button-true,
.switch-button-cont.switch-button-false {
    background-image: url(/public/img/switch-button-bg.png);
}

.switch-button-label {
    margin-right: 150px;
    min-height: 70px;
}

.switch-button-cont .expanded {
    width: 44px;
    height: 44px;
    left: 20px;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    transition: all 0.75s;
}

.switch-button-cont.switch-button-empty .expanded {
    background-image: url(/public/img/switch-button-empty.png);
    left: 20px;
}

.switch-button-cont.switch-button-true .expanded {
    background-image: url(/public/img/switch-button-not-active.png);
    left: 0px;
}

.switch-button-cont.switch-button-false .expanded {
    background-image: url(/public/img/switch-button-active.png);
    left: 42px;
}

.switch-button-cont label {
    display: none !important;
}

.switch-button-cont {
    position: relative;
    right: 0;
}


/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#loaderImage {
    height: 20px;
    width: 200px;
    border: 1px solid #ccc;
    position: fixed;
    background: url(/public/source/img/loader.gif) #fff no-repeat center center;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -100px;
}

.btn-reset {
    width: 60px;
    height: 60px;
}

.min-vh-50 {
    min-height: 50vh !important;
}

@media only screen and (min-width: 768px) {
    .columns-2{
        column-count: 2;
    }
}