// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@use "sass:selector";

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}


input.form-control-date  {
    @extend .form-control-icon;
    background: #fff !important;
}

.form-control-icon + .icon {
    position: absolute;
    top: 0;
    right: 16px;
    padding: 15px 30px;
    font-size: 30px;
    background: white;
    z-index: 0;
}

/* switch */

@mixin switch($res: 'sm') {
    
    $index: 1rem;
    $mainVal: 1rem;
    $fontSize: 0.7rem;
  
    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
        $fontSize: 1rem;
    } @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
        $fontSize: 1.3rem;
    } @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
        $fontSize: 1.5rem;
    }

    .custom-control-label {
        padding-left: calc(#{$index} + 1rem);
        padding-bottom: #{$mainVal};
        font-size: $fontSize;
    }

    .custom-control-label::before {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }
    
    .custom-control-label::after {
        content: "";
        top: 1px;
        left: 6px;
        width: calc(#{$mainVal} - 4px);
        height: calc(#{$mainVal} - 4px);
        content:  " ";
        background: $primary;
        border: 1px solid $primary;
        position: absolute;
        border-radius: 50%;
    }
    

    .custom-control-input:checked + label.custom-control-label::after {
        transform: translateX(calc(#{$mainVal} - 0.40rem));
        background: $white;
    }
    
    .custom-control-input:focus + .custom-control-label::before {
      border-color: $primary !important;
      box-shadow: 0 0 0 0.2rem rgba(255, 47, 69, 0.25) !important;
    }
    
    .custom-control-input:checked + .custom-control-label::before {
      border-color: $primary !important;
      background-color: $primary !important;
    }
    
    .custom-control-input:active + .custom-control-label::before {
      background-color: $primary !important;
      border-color: $primary !important;
    }
    
    .custom-control-input:focus:not(:checked)~.custom-control-label::before {
      border-color: $primary !important;
    }
    
    .custom-control-input-green:not(:disabled):active~.custom-control-label::before {
      background-color: $primary !important;
      border-color: $primary !important;
    }
    
}

.custom-switch.custom-switch-sm {
    @include switch();
}

.custom-switch.custom-switch-md {
    @include switch('md');
}

.custom-switch.custom-switch-lg {
    @include switch('lg');
}

.custom-switch.custom-switch-xl {
    @include switch('xl');
}


/* buttons */

.button {
    
    border: 0;
    display: inline-block;
    padding: 20px;
    @extend .white;
    
    
    &-primary {
        @extend .button;
        @extend .bg-primary;
    }   
    
     
    &-secondary {
        @extend .button;
        @extend .bg-secondary;
    }
        
    @extend .d-block;
}


.generic-form {
  
    label {
    
        @extend .d-block, .font-size-14, .text-secondary;
        
        &.text-black {
            @extend .text-black;
        }
        
    }
        
    .form-check-label {
        @extend .text-primary;
        
        &-black {
            
            @extend .text-black;
              
            &::after {
                border-color: $black !important;
            }
        
            &::before {
                border-color: $black !important;
            }
        }
        
    }
    
    .form-control {
        
        @include unify-parent("input") {
            @extend .border, .border-primary;
        }
        
        .selectize-input {
            @extend .border, .border-primary;
        }
                                
        &-error {
            border: 1px solid $red !important;
        }
        
    }

    .selectize-input {
        padding: 17px;
    }
    
    .selectize-input:after {
        color: map-get($colors, "color-d6");  
    }
    
    input.form-control-error {
       @extend .form-control-error;       
    }
    
    .input-group {
    
        @extend .border, .border-primary;
    
        &.input-group-error {
            @extend .form-control-error;
        }
    
        .form-control {
            border: none !important;
        }
        
        input {
            
        }
    
        .input-group-text {

            font-size: 16px !important;
        
        }
    
    }
    
    
        
}


.form-control-short {
    @extend .form-control;
    width: auto;
    display: inline-block;
    border: 0 !important;
}

.form-control-icon  {
    @extend .form-control;
    padding-right: 50px;
}

.form-control {
    
    &-error {
        border: 1px solid $red !important;
    }
}



/* flatpickr custom */

$flatpickrColor : map-get($colors, "color-z1");

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $flatpickrColor !important;
    border-color: $flatpickrColor !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $flatpickrColor !important;
}

.flatpickr-months .flatpickr-month {
  border-radius: 0;
}

.captcha-img{
	position: absolute;
	top: 8px;
}

input[type=radio], input[type=checkbox] {
    display: none;
}

label {
    
    > input[type=checkbox], input[type=radio] {
        display: initial;
    }
    
}

input[type=radio]+label, input[type=checkbox]+label,
input[type=checkbox]+input[type=hidden]+label{
    position: relative;
    display: block;
    padding-left: 30px;
    cursor: pointer;
    vertical-align: middle;
}

input[type=radio]+label::after {
    top: 7px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
    position: absolute;
    display: none;
    content: '';
}

input[type=radio]:checked+label::after {
    display: block;
}

input[type=radio]+label::before, input[type=checkbox]+label::before,
input[type=checkbox]+input[type=hidden]+label::before {
    border-radius: 50%;
    top: 1px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid $primary;
    position: absolute;
    background: $white;
}

input[type=radio]+label::before {
    border-radius: 50%;
}

input[type=checkbox]+label::before,
input[type=checkbox]+input[type=hidden]+label::before {
    border-radius: 3px;
}

input[type=checkbox]:checked+label::before,
input[type=checkbox]:checked+input[type=hidden]+label::before {
    background: $primary;
}

input[type=checkbox]:checked+label::after,
input[type=checkbox]:checked+input[type=hidden]+label::after {
    display: block;
    top: 3px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: $white;
    border-top: 0;
    border-left: 0;
    position: absolute;
    content: '';
}

.reset-flatpickr {
	right: 45px;
    bottom: 10px;
    
    @extend .bg-color-d6, .p-1, .position-absolute, .pointer;
    
}




/* slider */

label.checkbox-label .slider-container {
    position: relative;
    width: 80px;
    height: 44px;
}

label.checkbox-label input {
    display:none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($backgrounds, "grey");
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 26px;
    border: 2px solid map-get($colors, "color-d6");
}

.slider:before {
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    content: "";
    height: 44px;
    width: 44px;
    right: 36px;
    bottom: -2px;
    background-color: map-get($colors, "color-d6");
    -webkit-transition: .4s;
    border: 2px solid map-get($colors, "color-d6");
    transition: .4s;
    content: attr(data-no);
    padding: 8px 7px;
    color: map-get($colors, "white");
}

input {
	border-radius: 0;
}

input:checked + .slider {
    background-color: map-get($backgrounds, "grey");
}

input:focus + .slider {
    box-shadow: 0 0 1px map-get($colors, "color-d6");
}

input:disabled + .slider {
	opacity: 0.3;
	cursor: not-allowed;
}

input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    -mos-transform: translateX(36px);
    transform: translateX(36px);
    background-color: map-get($colors, "color-d6");
    content: attr(data-yes);
    color: map-get($colors, "white");
    padding: 8px 16px;
}

.slider-container.btn {
	border: none;
}

.slider-container.btn:active {
	box-shadow: none;
}

.slider-label {
	line-height: 44px;
}

.checkbox-label {
	margin-bottom: 0;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.cursor-move {
	cursor: move;
}

/* fileinput */

.fileinput-name {
    @extend .d-block, .p-3;
}
