// -----------------------------------------------------------------------------
// This file contains all styles related to the icon component.
// -----------------------------------------------------------------------------
$icon-path: '../fonts';
//$icon-path: 'http://localhost:8080/agv-main-util-web/assets/fonts';
  
$icon-name: 'icomoon';
@font-face {
    font-family: '#{$icon-name}';
    src:  url('#{$icon-path}/#{$icon-name}.eot');
    src:  url('#{$icon-path}/#{$icon-name}.eot#iefix') format('embedded-opentype'),
    url('#{$icon-path}/#{$icon-name}.ttf') format('truetype'),
    url('#{$icon-path}/#{$icon-name}.woff2') format('woff2'),
    url('#{$icon-path}/#{$icon-name}.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icon-name}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-color,
.icon-color path {
    fill: map-get($colors, "color-f1");
    stroke: map-get($colors, "color-f1");
}

.icon-empty:before {
    content: " ";
}

.icon-about:before {
    content: "\e900";
}
.icon-calendar:before {
    content: "\e901";
}
.icon-download:before {
    content: "\e902";
}
.icon-facebook:before {
    content: "\e903";
}
.icon-file:before {
    content: "\e904";
}
.icon-folder:before {
    content: "\e905";
}
.icon-info:before {
    content: "\e906";
}
.icon-instagram:before {
    content: "\e907";
}
.icon-left:before {
    content: "\e908";
}
.icon-linkedin:before {
    content: "\e909";
}
.icon-marker:before {
    content: "\e90a";
}
.icon-notice:before {
    content: "\e90b";
}
.icon-notice-full:before {
    content: "\e920";
}
.icon-press:before {
    content: "\e90c";
}
.icon-right:before {
    content: "\e90d";
}
.icon-right_small:before {
    content: "\e917";
}
.icon-search:before {
    content: "\e90e";
}
.icon-talk:before {
    content: "\e90f";
}
.icon-themes:before {
    content: "\e910";
}
.icon-twitter:before {
    content: "\e911";
}
.icon-user:before {
    content: "\e912";
}
.icon-video:before {
    content: "\e913";
}
.icon-basket:before {
    content: "\e914";
}
.icon-xing:before {
    content: "\e915";
}
.icon-youtube:before {
    content: "\e916";
}
.icon-pin:before {
    content: "\e918";
}
.icon-user-full:before {
    content: "\e919";
}
.icon-sharing:before {
    content: "\e922";
}
.icon-headset:before {
    content: "\e923";
}
.icon-flags:before {
    content: "\e924";
}
.icon-end:before {
    content: "\e925";
}
.icon-lock:before {
    content: "\e926";
}
.icon-link:before {
    content: "\e927";
}
.icon-upload:before {
    content: "\e028";
}
.icon-arrow:before {
    content: "\e929";
}
/* bayme */
/*
      "audio" 
      "consulting"      
      "contract"  
      "graphic"  
      "information"
      "project"     
      "law"  
      "microsite"   
      "network"     
      "person"
      "position"  
      "photo" 
*/
.icon-b-finger:before {
    content: "\e800";
}
.icon-b-return-up:before {
    content: "\e802";
}
.icon-infotype-network:before,
.icon-b-network:before,
.icon-b-meeting:before {
    content: "\e803";
}
.icon-b-mobile:before {
    content: "\e804";
}
.icon-infotype-training_knowhow:before,
.icon-infotype-congress:before,
.icon-b-audience:before {
    content: "\e805";
}
.icon-b-house:before {
    content: "\e806";
}

.icon-infotype-person:before,
.icon-b-person:before,
.icon-b-contact:before {
    content: "\e807";
}
.icon-infotype-events:before,
.icon-infotype-event:before,
.icon-b-calendar:before {
    content: "\e808";
}
.icon-infotype-tool:before,
.icon-b-tool:before,
.icon-b-computer:before {
    content: "\e809";
}

.icon-infotype-publications:before,
.icon-infotype-publication:before,
.icon-b-book:before {
    content: "\e810";
}
.icon-b-user:before {
    content: "\e811";
}
.icon-b-meeting-point:before {
    content: "\e812";
}
.icon-b-mail:before {
    content: "\e813";
}
.icon-b-bin:before {
    content: "\e814";
}
.icon-b-camera:before {
    content: "\e815";
}
.icon-b-data-transfer:before {
    content: "\e816";
}
.icon-infotype-video:before,
.icon-b-filmflap:before {
    content: "\e818";
}
.icon-infotype-database:before,
.icon-b-database:before {
    content: "\e819";
}
.icon-b-circle-arrow:before {
    content: "\e820";
}

.icon-b-lock:before {
    content: "\e821";
}

.icon-b-lock-strong:before {
    content: "\e871";
}

.icon-b-phone:before {
    content: "\e822";
}
.icon-b-presentation:before {
    content: "\e823";
}
.icon-b-download:before {
    content: "\e824";
}
.icon-b-road:before {
    content: "\e825";
}

.icon-b-audio:before,
.icon-b-megaphone:before {
    content: "\e826";
}

.icon-infotype-press_announcement:before,
.icon-infotype-information:before,
.icon-infotype-contract:before,
.icon-b-information:before,
.icon-b-contract:before,
.icon-b-document:before {
    content: "\e827"; 
}
.icon-b-escalator:before {
    content: "\e828";
}
.icon-b-print:before {
    content: "\e858";
}
.icon-b-cart:before {
    content: "\e830";
}
.icon-infotype-microsite:before,
.icon-b-microsite:before,
.icon-b-quad-arrow:before {
    content: "\e831";
}

.icon-b-cunsulting:before,
.icon-infotype-cunsulting:before,
.icon-b-table:before {
    content: "\e832";
}
.icon-infotype-checklist:before,
.icon-b-checklist:before,
.icon-infotype-tools_template:before,
.icon-infotype-projects:before,
.icon-infotype-tools:before,
.icon-b-project:before,
.icon-b-notes:before {
    content: "\e833";
}
.icon-b-single-arrow:before {
    content: "\e834";
}
.icon-b-triple-arrow:before {
    content: "\e835";
}
.icon-b-double-arrow:before {
    content: "\e837";
}
.icon-b-back-arrow:before {
    content: "\e836";
}
.icon-b-arrow:before {
    content: "\e838";
}
.icon-b-download:before {
    content: "\e840";
}
.icon-b-logout:before {
    content: "\e831";
}
.icon-b-reload:before {
    content: "\e842";
}
.icon-b-card:before {
    content: "\e843";
}
.icon-b-close:before {
    content: "\e844";
}
.icon-b-clock:before {
    content: "\e845";
}
.icon-b-marker:before {
    content: "\e846";
}
.icon-b-minus:before {
    content: "\e848";
}
.icon-b-plus:before {
    content: "\e847";
}
.icon-b-home:before {
    content: "\e849";
}
.icon-b-exit:before {
    content: "\e850";
}
.icon-b-world:before {
    content: "\e851";
}
.icon-b-folder:before {
    content: "\e852";
}
.icon-b-scale:before {
    content: "\e853";
}
.icon-b-calculator:before {
    content: "\e854";
}
.icon-b-calculator:before {
    content: "\e854";
}
.icon-b-star:before {
    content: "\e855";
}
.icon-b-tip:before {
    content: "\e856";
}
.icon-b-checked:before {
    content: "\e857";
}
.icon-b-polaroid:before {
    content: "\e859";
}
.icon-b-corona:before {
    content: "\e860";
}
.icon-b-limit:before {
    content: "\e862";
}
.icon-b-favorite:before {
    content: "\e863";
}
.icon-b-favorited:before {
    content: "\e980";
}
.icon-b-alarm:before {
    content: "\e981";
}
.icon-b-excel:before {
    content: "\e982";
}

.icon-strong {
    font-weight: 900;
}

/* ROTATION */   
$rotations: (
    90,
    180,
    270
);


.icon-rotate {
    
    display: inline-block;
    
    @each $rotation in $rotations {
        &-#{$rotation}{
            @extend .icon-rotate;
            transform: rotate(#{$rotation}deg);
        }
    }
}


/* SIZE */
$icon-sizes: (
    'small': 1rem,
    'medium': 2rem,
    'large': 3rem,
    'big': 4rem,
    'super': 5rem
);
.icon {
    @each $name, $size in $icon-sizes {
        &-#{$name}{
            font-size: #{$size};
        }
    }
}