// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


$logoSize: 126px;
$logoSizeSmall: 52px;
 
    
.header {
    @extend .container, .py-4, .bg-white;
   
    position: fixed;
    z-index: 3;
    top: 0%;
    
    @include media-breakpoint-up(sm) {
        @include fixed_header(sm);
    }
    @include media-breakpoint-up(md) {
        @include fixed_header(md);
    }
    @include media-breakpoint-up(lg) { 
        @include fixed_header(lg);
    }
    @include media-breakpoint-up(xl) { 
        @include fixed_header(xl);
    }     


    &-navi {
        
        @extend 
        .serif,
        .position-absolute,
        .bg-grey, 
        .p-5,
        .mt-3;

        min-width: 260px;
        right: -30px;
        top: 0;
        z-index: 10;
        padding-top: 80px !important;
    }   
       
}

.logo {
 
    $logoMobile: calc( #{$logoSize} / 2 );

    
    
    max-height: $logoSize;
    transition: max-height 1s ease;
    
    @include respond-below(md) {
        max-height: #{$logoMobile};
    } 
    
    &-small {
        max-height: $logoSizeSmall;
     
        @include respond-below(md) {
            max-height: #{$logoMobile};
        }         
    }
        
    &-text {
        @extend .font-size-lg-22, .d-none, .d-lg-block;
        margin: 0 0 12px 40px;
        transition: margin 1s ease;
        
        &-small {
            margin: 0 0 0 40px;
        }
        
    }    
    
}


/* BURGER MENU  */

@import '~sass-burger/_burger.scss';
 
.burger-icon {
	@include burger(35px, 3px, 9px, $black);
}

.menu-button {
    cursor: pointer;
    z-index: 11;
    margin-top: 10px;
}

.menu-button-open {
    @extend .bg-white;
    padding: 10px;
    margin: -10px;
    height: 49px;
    margin-top: 0px;

    .burger-icon, .burger-icon::before, .burger-icon::after {
        background-color: map-get($colors, "color-e1") !important; 
    }
}


/* TOOLS */

.header-tools {
    
    @extend .pt-4;
  
    .language-switch {
        @extend .mt-1, .d-none, .d-md-block;
        
        a {
            @extend .pt-2, .pl-3, .pr-3, .pb-1, .white, .font-size-25, .font-light;
        }
    }
    
    .toolbar-badge {
        bottom: auto !important;
    }

    
    .nav-tabs {
        position: relative;
    }
        
    .icon,
    .nav-item a {
        @extend .black;
    }
            
    .nav-item {
        @extend .d-flex;
        
        &-logout {
            margin-top: 0 !important;
            margin-right: 0 !important;        
        }
        
    }
    
    .icon {
        @extend .font-size-26;    
    }   
    
    .search-header {
    } 
    
    /* SEARCH */
    
    .search-header {
    
        position: relative;


        .xdsoft_autocomplete {
            position: absolute;
            right: 15px;
        }
    
    
        &-button {
            @extend .bg-transparent, .border-0, .mr-3;
            margin-top: 10px;
            position: relative;
            z-index: 2;
            
            
            &-open span {
                color: map-get($colors, "color-e1");
            }
        }
        
        &-input {
            display: none;
            width: 30vw;
            height: 45px;
            padding: 10px;
            border: 1px solid map-get($colors, "color-e1");
            background:  map-get($backgrounds, "white");
        }
          
    }
    
    .search-header-input {
    }
    
    .xdsoft_autocomplete_hint{
    	background: transparent;
    }
    
    
    .toolbar-nav-tabs .nav-link.active, .toolbar-nav-tabs .nav-item.show .nav-link {
        @extend .black;
        background-color: transparent;
        border-color: transparent;
    }  
               
    .toolbar-nav-tabs .nav-link:hover, .toolbar-nav-tabs .nav-link:focus {
        border-color: transparent;
    }
    
    .toolbar-tab-pane {
        top: 90px;
    }
    
    .toolbar-nav-tabs .active:after {
        background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAPCAYAAAAYjcSfAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAHaADAAQAAAABAAAADwAAAADnk/CJAAAAmElEQVQ4Eb3UsRFAMBTGcWEDG5hCS0tjEGcFzOAskQGcUm0iqvC443KJeA/xlV/z6/4sr9tKCFE6v4z1rh9k3jh0QxinbDUju+4ONkUyewDZh08QvA21C8ughNqBVVBBv4X1oBb9Br4GL9F3sBk0os/ge/AWpcE4EIXiYDyIRs0wDSShepgOklEZZhPEG1oK/y/jnB8JpYILx79qtJJOel4AAAAASUVORK5CYII=);
        bottom: 40px;
        left: 15px;
    }
        
}

.header-transparent{
    background-color: transparent!important;
}


