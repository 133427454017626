// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
    
    $fonts-path: '../fonts';

    @font-face{
        font-family:"Geogrotesque W01 Lt";
        src:url("#{$fonts-path}/69fc4714-05d2-488b-838b-5925ef0197dd.woff2") format("woff2"),url("#{$fonts-path}/d35a7b59-82a2-4f8a-8287-cc5423e1f0ab.woff") format("woff");
    }
    
    @font-face{
        font-family:"GeogrotesqueW01-Regular1185161";
        src:url("#{$fonts-path}/38914808-31f7-4169-a962-60b41b189056.woff2") format("woff2"),url("#{$fonts-path}/ae7eda9b-5729-4029-8ea8-1f4b2feb6595.woff") format("woff");
    }
    @font-face{
        font-family:"Geogrotesque W01 Medium1185174";
        src:url("#{$fonts-path}/dd501f4a-1a91-4d73-b4cf-de3ba26bd793.woff2") format("woff2"),url("#{$fonts-path}/149719fe-5b81-4b67-a95a-3a5246a84032.woff") format("woff");
    }
    @font-face{
        font-family:"HelveticaNeueW01-Thin";
        src:url("#{$fonts-path}/aff68211-86bb-476d-882e-f7a3face144c.woff2") format("woff2"),url("#{$fonts-path}/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff") format("woff");
    }
    @font-face{
        font-family:"HelveticaNeueW01-45Ligh";
        src:url("#{$fonts-path}/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2") format("woff2"),url("#{$fonts-path}/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff") format("woff");
    }
    @font-face{
        font-family:"HelveticaNeueW01-55Roma";
        src:url("#{$fonts-path}/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2") format("woff2"),url("#{$fonts-path}/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff");
    }
    @font-face{
        font-family:"HelveticaNeueW01-56It";
        src:url("#{$fonts-path}/462d508d-efc7-478f-b84e-288eeb69a160.woff2") format("woff2"),url("#{$fonts-path}/2c056da8-4920-4e20-8c69-8a6b315458a9.woff") format("woff");
    }
    @font-face{
        font-family:"HelveticaNeueW01-75Bold";
        src:url("#{$fonts-path}/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2") format("woff2"),url("#{$fonts-path}/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Haas Unica W01 Light";
        src:url("#{$fonts-path}/e5716290-d41e-4c97-a27c-7a20a46ddf45.woff2") format("woff2"),url("#{$fonts-path}/b681571f-aab2-4935-a99c-6ee1063ce638.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Haas Unica W01 Regular";
        src:url("#{$fonts-path}/e0d80810-f7e3-4fea-8c57-ef8116f0465d.woff2") format("woff2"),url("#{$fonts-path}/27645c8a-608b-4abf-a2f1-c4407b576723.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Haas Unica W01 Medium It";
        src:url("#{$fonts-path}/2418a824-3af2-42d7-a62e-f7a77cf487d1.woff2") format("woff2"),url("#{$fonts-path}/86b76db5-9343-473a-a106-60683cec7626.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Haas Unica W01 Bold";
        src:url("#{$fonts-path}/39bb8fb3-84a4-4ab8-ae45-db1834f5d807.woff2") format("woff2"),url("#{$fonts-path}/96fd4fb4-75db-412f-986d-d65380ee0ccf.woff") format("woff");
    }


